import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CompanyData,
  CompanyEditForm,
  ErrorAlert
} from "components/common/old";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { CTABlock, Wrapper } from "./Company.styles";
import { Alert, Button, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import {
  CloseOutlined,
  CreditCardOutlined,
  DeleteOutlined,
  EditOutlined
} from "@ant-design/icons";
import { getUser } from "store/user/thunk";
import { deleteEntity } from "store/entities/delete/thunk";
import {
  entityDeleteActions,
  entityDeleteSelectors
} from "store/entities/delete";
import { TEntity } from "types";
import { updateEntity } from "store/entities/update/thunk";
import {
  entityUpdateActions,
  entityUpdateSelectors
} from "store/entities/update";

export const Company = () => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    entityDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    entityUpdateSelectors.getState
  );

  const { entity_id } = useParams<{ entity_id: string }>();

  const entity = useMemo(
    () =>
      (user?.entities || []).find(
        (entity) => entity?.entity?.entity_id === Number(entity_id)
      )?.entity,
    [entity_id, user?.entities]
  );

  const canUpdateDelete = useMemo(
    () =>
      (user?.cabinet?.cabinet_id === entity?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin,
    [
      entity?.cabinet_id,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id,
      user?.is_admin
    ]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TEntity) => {
      dispatch(updateEntity({ ...entity, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          dispatch(getUser());
        });
    },
    [dispatch, entity, onCancelEdit]
  );

  const onDelete = useCallback(() => {
    entity &&
      dispatch(deleteEntity(entity?.entity_id))
        .unwrap()
        .then(() => {
          getUser();
        });
  }, [dispatch, entity]);

  useEffect(() => {
    return () => {
      dispatch(entityDeleteActions.clearState());
      dispatch(entityUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {entity?.full_name || "Компания"}
      </Typography.Title>

      {entity ? (
        <>
          {canUpdateDelete && (
            <CTABlock>
              <Button
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={isEditing ? onCancelEdit : onEdit}
              >
                {isEditing ? "Отмена" : "Редактировать"}
              </Button>

              {!isEditing && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={onDelete}
                  loading={deleteIsLoading}
                >
                  Удалить
                </Button>
              )}
            </CTABlock>
          )}

          {!isEditing && <ErrorAlert error={deleteError} />}

          {!isEditing && (
            <Link to="accounts">
              <Button icon={<CreditCardOutlined />}>Счета компании</Button>
            </Link>
          )}

          {isEditing ? (
            <CompanyEditForm
              entity={entity}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <CompanyData entity={entity} />
          )}
        </>
      ) : (
        <Alert message="Компания не найдена" type="error" showIcon />
      )}
    </Wrapper>
  );
};
