import React, { useCallback, useEffect } from "react";
import { ApprovementsTable, ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { userSelectors } from "store/user";
import {
  approvementsByUserIdActions,
  approvementsByUserIdSelectors
} from "store/approvements/byUserId";
import {
  approvementUpdateActions,
  approvementUpdateSelectors
} from "store/approvements/update";
import { getApprovementsByUserId } from "store/approvements/byUserId/thunk";
import { updateApprovement } from "store/approvements/update/thunk";

export const GeneralApprovements = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { approvements } = useAppSelector(
    approvementsByUserIdSelectors.getState
  );
  const { isLoading, error } = useAppSelector(
    approvementUpdateSelectors.getState
  );

  const getApprovements = useCallback(() => {
    user && dispatch(getApprovementsByUserId(user?.user_id));
  }, [dispatch, user]);

  const onUpdate = useCallback(
    (approvement_id: number, approvement_status_id: number) => {
      dispatch(updateApprovement({ approvement_id, approvement_status_id }))
        .unwrap()
        .then(() => {
          getApprovements();
        });
    },
    [dispatch, getApprovements]
  );

  useEffect(() => {
    getApprovements();
  }, [dispatch, getApprovements]);

  useEffect(() => {
    return () => {
      dispatch(approvementsByUserIdActions.clearState());
      dispatch(approvementUpdateActions.clearState());
    };
  }, [dispatch]);
  return (
    <>
      <ErrorAlert error={error} />

      <ApprovementsTable
        approvements={approvements}
        onUpdate={onUpdate}
        isLoading={isLoading}
      />
    </>
  );
};
