import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { getOffersForBuyer } from "store/offersForBuyer/get/thunk";
import {
  offersForBuyerGetActions,
  offersForBuyerGetSelectors
} from "store/offersForBuyer/get";
import { Button, Table, Tag } from "antd";
import { PAGE_SIZES } from "constants/pagination";
import { TOfferForBuyer } from "types";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { toDisplayDate } from "utils/dates";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "utils/mappers";
import {
  offerForBuyerUpdateStatusActions,
  offerForBuyerUpdateStatusSelectors
} from "store/offersForBuyer/update_status";
import { updateOfferForBuyerStatus } from "store/offersForBuyer/update_status/thunk";
import { ErrorAlert } from "components/common/old";

export const OfferApprovementsForBuyer = () => {
  const dispatch = useAppDispatch();

  const { offersForBuyer, totalElements, page, size } = useAppSelector(
    offersForBuyerGetSelectors.getState
  );
  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForBuyerUpdateStatusSelectors.getState
  );

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForBuyer());
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForBuyerGetActions.setPage(page));
      } else {
        dispatch(offersForBuyerGetActions.setPage(1));
        dispatch(offersForBuyerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  const onUpdateStatus = useCallback(
    (offer_id: number, new_status_id: number) => {
      dispatch(
        updateOfferForBuyerStatus({
          offer_id,
          new_status_id
        })
      )
        .unwrap()
        .then(() => {
          getOffersArr();
        });
    },
    [dispatch, getOffersArr]
  );

  const columns: ColumnsType<TOfferForBuyer> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "approvement_name",
        render: (value: TOfferForBuyer) => (
          <Link to={`/offers/${value?.offer_id}`}>
            Предложение #{value?.offer_id}{" "}
            {value?.create_dt && `от ${toDisplayDate(value?.create_dt)}`}
          </Link>
        )
      },
      {
        title: "Статус",
        dataIndex: "status_id",
        render: (value: number) => {
          const statusData = offerStatusIdMapper[value];
          return statusData ? (
            <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
          ) : (
            value
          );
        }
      },
      {
        title: "Заявка",
        dataIndex: "application_id",
        render: (value: number) => (
          <Link to={`/applications/${value}`}>Заявка #{value}</Link>
        )
      },
      {
        key: "approve",
        render: (value: TOfferForBuyer) => (
          <Button
            onClick={() => onUpdateStatus(value?.offer_id, 105)}
            loading={statusIsLoading}
            icon={<CheckOutlined />}
          >
            Принять
          </Button>
        )
      },
      {
        key: "cancel",
        render: (value: TOfferForBuyer) => (
          <Button
            onClick={() => onUpdateStatus(value?.offer_id, 102)}
            loading={statusIsLoading}
            danger
            icon={<CloseOutlined />}
          >
            Отклонить
          </Button>
        )
      }
    ],
    [onUpdateStatus, statusIsLoading]
  );

  useEffect(() => {
    dispatch(offersForBuyerGetActions.setSearch("statusId:124"));
    getOffersArr();
  }, [dispatch, getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForBuyerGetActions.clearState());
      dispatch(offerForBuyerUpdateStatusActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <ErrorAlert error={statusError} />

      <Table
        dataSource={offersForBuyer}
        columns={columns}
        showHeader={false}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </>
  );
};
