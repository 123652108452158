import dayjs from "dayjs";
import { DATE_TIME_FORMAT } from "constants/dates";
import { fromIsoDateTime } from "./fromIsoDateTime";

export const toDisplayDateTime = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  if (typeof date === "string") {
    return fromIsoDateTime(date)?.format(DATE_TIME_FORMAT);
  } else {
    return dayjs(date).format(DATE_TIME_FORMAT);
  }
};
