import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TAccount } from "types";
import { Wrapper } from "./AccountsTab.styles";
import { Button, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { AccountEditForm, AccountsTable } from "components/common/old";
import {
  accountByCabinetIdActions,
  accountsByCabinetIdSelectors
} from "store/accounts/byCabinet";
import { accountAddActions, accountAddSelectors } from "store/accounts/add";
import {
  accountUpdateActions,
  accountUpdateSelectors
} from "store/accounts/update";
import {
  accountDeleteActions,
  accountDeleteSelectors
} from "store/accounts/delete";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { addAccount } from "store/accounts/add/thunk";
import { updateAccount } from "store/accounts/update/thunk";
import { deleteAccount } from "store/accounts/delete/thunk";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import {
  entitiesByCabinetIdActions,
  entitiesByCabinetIdSelectors
} from "store/entities/byCabinetId";

export const AccountsTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    accountAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    accountUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    accountDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [accountInEdit, setAccountInEdit] = useState<TAccount | undefined>(
    undefined
  );
  const [selectedCabinet, setSelectedCabinet] = useState<number | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getAccounts = useCallback(
    (cabinetId: number) => {
      dispatch(getAccountsByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const getEntities = useCallback(
    (cabinetId: number) => {
      dispatch(getEntitiesByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const account = accounts?.find((account) => account?.account_id === id);
      setAccountInEdit(account);
    },
    [accounts]
  );
  const onCancelEdit = useCallback(() => {
    setAccountInEdit(undefined);
  }, []);

  const onAddAccount = useCallback(
    (values: TAccount) => {
      dispatch(
        addAccount({
          ...values,
          /* TODO убрать BIK, OKATO */
          BIK: values?.bik,
          OKATO: values?.okato
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          selectedCabinet && getAccounts(selectedCabinet);
        });
    },
    [dispatch, getAccounts, onCancelAdd, selectedCabinet]
  );
  const onEditAccount = useCallback(
    (values: TAccount) => {
      dispatch(
        updateAccount({
          ...accountInEdit,
          ...values,
          /* TODO убрать BIK, OKATO */
          BIK: values?.bik,
          OKATO: values?.okato
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getAccounts(selectedCabinet);
        });
    },
    [accountInEdit, dispatch, getAccounts, onCancelEdit, selectedCabinet]
  );
  const onDeleteAccount = useCallback(
    (id: number) => {
      dispatch(deleteAccount(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getAccounts(selectedCabinet);
        });
    },
    [dispatch, getAccounts, selectedCabinet]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getAccounts(selectedCabinet);
  }, [dispatch, getAccounts, selectedCabinet]);

  useEffect(() => {
    selectedCabinet && getEntities(selectedCabinet);
  }, [dispatch, getEntities, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(accountAddActions.clearState());
      dispatch(accountUpdateActions.clearState());
      dispatch(accountDeleteActions.clearState());
      dispatch(accountByCabinetIdActions.clearState());
      dispatch(entitiesByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={setSelectedCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      {selectedCabinet && (
        <>
          {!accountInEdit && (
            <Button
              icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
              onClick={isAdding ? onCancelAdd : onAdd}
            >
              {isAdding ? "Отмена" : "Добавить"}
            </Button>
          )}

          {accountInEdit ? (
            <>
              <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                Отмена
              </Button>
              <AccountEditForm
                account={accountInEdit}
                onSubmit={onEditAccount}
                isLoading={updateIsLoading}
                error={updateError}
              />
            </>
          ) : isAdding ? (
            <AccountEditForm
              entities={entities}
              withEntity
              onSubmit={onAddAccount}
              isLoading={addIsLoading}
              error={addError}
            />
          ) : (
            <AccountsTable
              accounts={accounts}
              onEdit={onEdit}
              onDelete={onDeleteAccount}
              isDeleting={deleteIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
