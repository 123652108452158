import React, { FC, ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { theme, Typography } from "antd";
import { StyledPanel } from "./CardButton.styles";

export type TCardButtonProps = {
  icon: ReactNode;
  title: string;
  to?: string;
  onClick?: () => void;
  gridColumn?: number;
};

export const CardButton: FC<TCardButtonProps> = ({
  icon,
  title,
  to,
  onClick,
  gridColumn
}) => {
  const navigate = useNavigate();
  const {
    token: { colorPrimary }
  } = theme.useToken();

  const onClickHandler = useCallback(() => {
    to ? navigate(to) : onClick?.();
  }, [navigate, onClick, to]);

  return (
    <StyledPanel onClick={onClickHandler} darkened gridColumn={gridColumn}>
      <Typography.Title level={3} style={{ color: colorPrimary, margin: 0 }}>
        {icon}
      </Typography.Title>
      <Typography.Text strong style={{ flex: 1 }}>
        {title}
      </Typography.Text>
    </StyledPanel>
  );
};
