import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import {
  colleagueAddActions,
  colleagueAddSelectors
} from "store/colleagues/add";
import { addColleague } from "store/colleagues/add/thunk";
import {
  colleaguesByCabinetActions,
  colleaguesByCabinetSelectors
} from "store/colleagues/byCabinet";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import { deleteColleague } from "store/colleagues/delete/thunk";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { updateColleague } from "store/colleagues/update/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TPerson } from "types";
import { Wrapper } from "./UsersTab.styles";
import { Button, Form, Input, Modal, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { ErrorAlert, UserEditForm, UsersTable } from "components/common/old";
import { restorePassword } from "store/user/restorePassword/thunk";
import { restorePasswordActions } from "store/user/restorePassword";
import { updatePassword } from "store/user/updatePassword/thunk";
import { TUpdatePasswordRequest } from "api/unauthorized";
import {
  updatePasswordActions,
  updatePasswordSelectors
} from "store/user/updatePassword";
import {
  entitiesByCabinetIdActions,
  entitiesByCabinetIdSelectors
} from "store/entities/byCabinetId";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";

export const UsersTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    colleagueAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    colleagueDeleteSelectors.getState
  );
  const { isLoading: updatePassIsLoading, error: updatePassError } =
    useAppSelector(updatePasswordSelectors.getState);

  const [isAdding, setIsAdding] = useState(false);
  const [userInEdit, setUserInEdit] = useState<TPerson | undefined>(undefined);
  const [selectedCabinet, setSelectedCabinet] = useState<number | undefined>(
    undefined
  );
  const [updatePassUser, setUpdatePassUser] = useState<TPerson | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getColleagues = useCallback(
    (cabinetId: number) => {
      dispatch(getColleaguesByCabinet(cabinetId));
    },
    [dispatch]
  );

  const getEntities = useCallback(
    (cabinetId: number) => {
      dispatch(getEntitiesByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const user = users?.find((user) => user?.user_id === id);
      setUserInEdit(user);
    },
    [users]
  );
  const onCancelEdit = useCallback(() => {
    setUserInEdit(undefined);
  }, []);

  const onAddUser = useCallback(
    (values: TPerson) => {
      dispatch(
        addColleague({
          /* TODO убрать установку cabinet_role_name, cabinet_role_description */
          ...{ cabinet_role_name: "", cabinet_role_description: "" },
          ...values,
          cabinet: cabinets?.find(
            (cabinet) => cabinet?.cabinet_id === selectedCabinet
          )
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [cabinets, dispatch, getColleagues, onCancelAdd, selectedCabinet]
  );
  const onEditUser = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...userInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [dispatch, getColleagues, onCancelEdit, selectedCabinet, userInEdit]
  );
  const onDeleteUser = useCallback(
    (id: number) => {
      dispatch(deleteColleague(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [dispatch, getColleagues, selectedCabinet]
  );

  const onResetPassword = useCallback(
    (id: number) => {
      dispatch(
        restorePassword({
          email: users?.find((user) => user?.user_id === id)?.email || ""
        })
      );
    },
    [dispatch, users]
  );

  const openUpdatePasswordModal = useCallback(
    (id: number) => {
      const user = users?.find((user) => user?.user_id === id);
      setUpdatePassUser(user);
    },
    [users]
  );
  const closeUpdatePasswordModal = useCallback(() => {
    setUpdatePassUser(undefined);
  }, []);

  const onUpdatePassword = useCallback(
    (values: TUpdatePasswordRequest) => {
      dispatch(updatePassword(values))
        .unwrap()
        .then(() => {
          closeUpdatePasswordModal();
        });
    },
    [closeUpdatePasswordModal, dispatch]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getColleagues(selectedCabinet);
  }, [dispatch, getColleagues, selectedCabinet]);

  useEffect(() => {
    selectedCabinet && getEntities(selectedCabinet);
  }, [dispatch, getEntities, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(colleagueAddActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleaguesByCabinetActions.clearState());
      dispatch(restorePasswordActions.clearState());
      dispatch(updatePasswordActions.clearState());
      dispatch(entitiesByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <Wrapper>
        <Select
          placeholder="Выберите кабинет"
          options={cabinetsOptions}
          onChange={setSelectedCabinet}
          value={selectedCabinet}
          style={{ maxWidth: 300 }}
        />
        {selectedCabinet && (
          <>
            {!userInEdit && (
              <Button
                icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
                onClick={isAdding ? onCancelAdd : onAdd}
              >
                {isAdding ? "Отмена" : "Добавить"}
              </Button>
            )}

            {userInEdit ? (
              <>
                <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                  Отмена
                </Button>
                <UserEditForm
                  user={userInEdit}
                  entities={entities}
                  onSubmit={onEditUser}
                  isLoading={updateIsLoading}
                  error={updateError}
                  withCabinetRoleId
                />
              </>
            ) : isAdding ? (
              <UserEditForm
                entities={entities}
                onSubmit={onAddUser}
                isLoading={addIsLoading}
                error={addError}
                withCabinetRoleId
              />
            ) : (
              <UsersTable
                users={users}
                onEdit={onEdit}
                onDelete={onDeleteUser}
                onResetPassword={onResetPassword}
                onUpdatePassword={openUpdatePasswordModal}
                isDeleting={deleteIsLoading}
              />
            )}
          </>
        )}
      </Wrapper>
      <Modal
        title={`Установка пароля для пользователя ${updatePassUser?.full_name}`}
        open={!!updatePassUser}
        onCancel={closeUpdatePasswordModal}
        footer={null}
        destroyOnClose
      >
        <Form
          name="updatepass"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 28 }}
          // style={{ maxWidth: "600px" }}
          onFinish={onUpdatePassword}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelWrap
          colon={false}
          initialValues={{ email: updatePassUser?.email }}
        >
          <Form.Item<TUpdatePasswordRequest> label="Email" name="email">
            <Input disabled />
          </Form.Item>

          <Form.Item<TUpdatePasswordRequest>
            label="Пароль"
            name="newpassword"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Введите пароль"
              }
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={updatePassIsLoading}
            >
              Сохранить
            </Button>
          </Form.Item>

          <ErrorAlert error={updatePassError} />
        </Form>
      </Modal>
    </>
  );
};
