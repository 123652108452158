import { TFileMeta } from "types";

export const extansionIdMapper: Record<TFileMeta["file_extension"], string> = {
  136: "xlsx",
  137: "docx",
  138: "doc",
  139: "xls",
  140: "pdf",
  141: "png",
  142: "jpeg"
};
