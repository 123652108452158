import React from "react";
import { userSelectors } from "store/user";
import { useAppSelector } from "store/store";
import { Wrapper } from "./Approvements.styles";
import { Tabs, TabsProps, Typography } from "antd";
import {
  GeneralApprovements,
  OfferApprovementsForBuyer,
  OfferApprovementsForSeller
} from "./components";

export const Approvements = () => {
  const { user } = useAppSelector(userSelectors.getState);

  const tabs: TabsProps["items"] = [
    ...(!user?.cabinet?.is_seller
      ? [
          {
            key: "applications",
            label: "Заявки",
            children: <GeneralApprovements />
          }
        ]
      : []),
    {
      key: "offers",
      label: "Предложения",
      children: user?.cabinet?.is_seller ? (
        <OfferApprovementsForSeller />
      ) : (
        <OfferApprovementsForBuyer />
      )
    }
  ];

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Согласования
      </Typography.Title>

      <Tabs items={tabs} />
    </Wrapper>
  );
};
