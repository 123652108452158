export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 1080,
  XL: 1280,
  XXL: 1600
};

export const DEVICE = {
  SM: `(max-width: ${BREAKPOINTS.SM}px)`,
  MD: `(max-width: ${BREAKPOINTS.MD}px)`,
  LG: `(max-width: ${BREAKPOINTS.LG}px)`,
  XL: `(max-width: ${BREAKPOINTS.XL}px)`,
  XXL: `(max-width: ${BREAKPOINTS.XXL}px)`
};
