import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationData,
  EnlargedTag,
  Files,
  OfferEditForm,
  OffersTable
} from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Alert, Button, Tooltip, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "store/user";
import { Wrapper } from "./ApplicationForSeller.styles";
import { applicationStatusIdMapper } from "utils/mappers";
import { getApplicationForSellerById } from "store/applicationsForSeller/byId/thunk";
import {
  ApplicationForSellerByIdActions,
  applicationForSellerByIdSelectors
} from "store/applicationsForSeller/byId";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { offerForSellerAddSelectors } from "store/offersForSeller/add";
import { addOfferForSeller } from "store/offersForSeller/add/thunk";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { TOfferForSeller, TOfferForSellerRequest } from "types";
import { getOfferForSellerByApplicationId } from "store/offersForSeller/byApplicationId/thunk";
import {
  offersForSellerByApplicationIdActions,
  offersForSellerByApplicationIdSelectors
} from "store/offersForSeller/byApplicationId";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";
import { getFilesByObject } from "store/files/byObject/thunk";

export const ApplicationForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { application } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );
  const { isLoading: offerAddIsLoading, error: offerAddError } = useAppSelector(
    offerForSellerAddSelectors.getState
  );
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { offersForSeller } = useAppSelector(
    offersForSellerByApplicationIdSelectors.getState
  );
  const { files } = useAppSelector(filesByObjectSelectors.getState);

  const [isAddingOffer, setIsAddingOffer] = useState(false);

  const { application_id } = useParams<{ application_id: string }>();

  const statusData = useMemo(
    () =>
      application?.application_status_id
        ? applicationStatusIdMapper[application?.application_status_id]
        : undefined,
    [application?.application_status_id]
  );

  const canAddOffer = useMemo(
    () =>
      user?.entities?.some(
        (entity) =>
          entity?.role_id >= 2 ||
          (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
            user?.cabinet_role_id === 2)
      ),
    [user]
  );

  const defaultOffer: TOfferForSeller = useMemo(
    () =>
      ({
        creator: user,
        application_id: application?.application_id
      }) as TOfferForSeller,
    [application, user]
  );

  const getApplication = useCallback(() => {
    return dispatch(getApplicationForSellerById(Number(application_id)));
  }, [application_id, dispatch]);

  const onAddOffer = useCallback(() => {
    setIsAddingOffer(true);
  }, []);
  const onCancelAddOffer = useCallback(() => {
    setIsAddingOffer(false);
  }, []);

  const getOffers = useCallback(
    (application_id: number) => {
      dispatch(getOfferForSellerByApplicationId(application_id));
    },
    [dispatch]
  );

  const onAddOfferToApplication = useCallback(
    (values: TOfferForSellerRequest) => {
      dispatch(addOfferForSeller(values))
        .unwrap()
        .then(() => {
          onCancelAddOffer();
          application && getOffers(application?.application_id);
        });
    },
    [application, dispatch, getOffers, onCancelAddOffer]
  );

  const getAccounts = useCallback(() => {
    user?.cabinet &&
      dispatch(getAccountsByCabinetId(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const getFiles = useCallback(
    (application_id: number) => {
      dispatch(
        getFilesByObject({
          id: application_id,
          type: 134
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (!user?.cabinet?.is_seller) {
      navigate("/applications", { replace: true, relative: "path" });
    }
  }, [navigate, user]);

  useEffect(() => {
    application_id && getApplication();
  }, [application_id, getApplication, getOffers]);

  useEffect(() => {
    application && getOffers(application?.application_id);
  }, [application, getOffers]);

  useEffect(() => {
    application && getFiles(application?.application_id);
  }, [application, getFiles]);

  useEffect(() => {
    !accounts && getAccounts();
  }, [accounts, getAccounts]);

  useEffect(() => {
    return () => {
      dispatch(ApplicationForSellerByIdActions.clearState());
      dispatch(offersForSellerByApplicationIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px"
        }}
      >
        {application?.application_name || "Заявка"}
        {statusData ? (
          <Tooltip title={statusData?.description}>
            <EnlargedTag color={statusData?.color}>
              {statusData?.shortName}
            </EnlargedTag>
          </Tooltip>
        ) : (
          application?.application_status_id
        )}
      </Typography.Title>

      {!!canAddOffer && (
        <Button
          icon={isAddingOffer ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAddingOffer ? onCancelAddOffer : onAddOffer}
        >
          {isAddingOffer ? "Отмена" : "Добавить предложение"}
        </Button>
      )}

      {application ? (
        isAddingOffer ? (
          <OfferEditForm
            offer={defaultOffer}
            application={application}
            user={user}
            accounts={accounts}
            onSubmit={onAddOfferToApplication}
            isLoading={offerAddIsLoading}
            error={offerAddError}
          />
        ) : (
          <>
            {!!offersForSeller?.length && (
              <OffersTable
                offers={offersForSeller}
                pagination={false}
                withoutApplication
                forSeller
              />
            )}

            <ApplicationData application={application} forSeller />

            <Files
              id={application?.application_id}
              type="application"
              files={files}
              canEdit={false}
            />
          </>
        )
      ) : (
        <Alert message="Данные по заявке отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
