import dayjs from "dayjs";
import { DATE_FORMAT } from "constants/dates";
import { fromIsoDate } from "./fromIsoDate";

export const toDisplayDate = (date?: string | Date) => {
  if (!date) {
    return undefined;
  }

  if (typeof date === "string") {
    return fromIsoDate(date)?.format(DATE_FORMAT);
  } else {
    return dayjs(date).format(DATE_FORMAT);
  }
};
