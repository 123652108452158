import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { AccountEditForm, Panel } from "components/common/old";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  CloseOutlined,
  CreditCardOutlined,
  PlusOutlined
} from "@ant-design/icons";
import { Wrapper } from "./Accounts.styles";
import { Button, List, Typography } from "antd";
import { Link, useParams } from "react-router-dom";
import { TAccount } from "types";
import { accountsByEntityIdSelectors } from "store/accounts/byEntity";
import { accountAddActions, accountAddSelectors } from "store/accounts/add";
import { getAccountsByEntityId } from "store/accounts/byEntity/thunk";
import { addAccount } from "store/accounts/add/thunk";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";

type TAccountsProps = {
  byCabinet?: boolean;
};

export const Accounts: FC<TAccountsProps> = ({ byCabinet }) => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { accounts } = useAppSelector(accountsByEntityIdSelectors.getState);
  const { accounts: cabinetAccounts } = useAppSelector(
    accountsByCabinetIdSelectors.getState
  );
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    accountAddSelectors.getState
  );

  const { entity_id } = useParams<{ entity_id: string }>();

  const [isAdding, setIsAdding] = useState(false);

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === Number(entity_id)
      )?.entity,
    [entity_id, user?.entities]
  );

  const accountsData = useMemo(
    () =>
      (byCabinet
        ? cabinetAccounts
        : entity_id
          ? accounts[Number(entity_id)]
          : []
      )?.map((account) => ({
        value: account?.name,
        label: `${account?.bank_name} (${account?.number})`,
        icon: <CreditCardOutlined />,
        id: account?.account_id,
        entityId: account?.entity_id
      })),
    [accounts, byCabinet, cabinetAccounts, entity_id]
  );

  const canAdd = useMemo(
    () =>
      !byCabinet &&
      ((user?.cabinet?.cabinet_id === entity?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
        user?.is_admin),
    [
      byCabinet,
      entity?.cabinet_id,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id,
      user?.is_admin
    ]
  );

  const getAccountsByEntity = useCallback(() => {
    entity_id && dispatch(getAccountsByEntityId(Number(entity_id)));
  }, [dispatch, entity_id]);

  const getAccountsByCabinet = useCallback(() => {
    user?.cabinet &&
      dispatch(getAccountsByCabinetId(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddAccount = useCallback(
    (values: TAccount) => {
      dispatch(
        addAccount({
          ...values,
          entity_id: entity?.entity_id!,
          /* TODO убрать BIK, OKATO */
          BIK: values?.bik,
          OKATO: values?.okato
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          getAccountsByEntity();
        });
    },
    [dispatch, entity?.entity_id, getAccountsByEntity, onCancelAdd]
  );

  useEffect(() => {
    if (!byCabinet) {
      getAccountsByEntity();
    }
  }, [byCabinet, getAccountsByEntity]);

  useEffect(() => {
    if (byCabinet) {
      !cabinetAccounts && getAccountsByCabinet();
    }
  }, [byCabinet, cabinetAccounts, getAccountsByCabinet]);

  useEffect(() => {
    return () => {
      dispatch(accountAddActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Счета
      </Typography.Title>

      {canAdd && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      <Panel
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {isAdding ? (
          <AccountEditForm
            onSubmit={onAddAccount}
            isLoading={addIsLoading}
            error={addError}
          />
        ) : (
          <List
            dataSource={accountsData}
            renderItem={(item) => (
              <Link
                to={
                  byCabinet
                    ? `/companies/${item?.entityId}/accounts/${item?.id}`
                    : `${item?.id}`
                }
              >
                <List.Item>
                  <List.Item.Meta
                    avatar={item?.icon}
                    title={item?.value}
                    description={item?.label}
                  />
                </List.Item>
              </Link>
            )}
          />
        )}
      </Panel>
    </Wrapper>
  );
};
