import React, { useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Table, Tag } from "antd";
import { PAGE_SIZES } from "constants/pagination";
import { TOfferForSeller } from "types";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { toDisplayDate } from "utils/dates";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "utils/mappers";
import {
  offersForSellerGetActions,
  offersForSellerGetSelectors
} from "store/offersForSeller/get";
import {
  offerForSellerUpdateStatusActions,
  offerForSellerUpdateStatusSelectors
} from "store/offersForSeller/update_status";
import { getOffersForSeller } from "store/offersForSeller/get/thunk";
import { updateOfferForSellerStatus } from "store/offersForSeller/update_status/thunk";
import { ErrorAlert } from "components/common/old";

export const OfferApprovementsForSeller = () => {
  const dispatch = useAppDispatch();

  const { offersForSeller, totalElements, page, size } = useAppSelector(
    offersForSellerGetSelectors.getState
  );
  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForSellerUpdateStatusSelectors.getState
  );

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForSeller());
  }, [dispatch]);

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForSellerGetActions.setPage(page));
      } else {
        dispatch(offersForSellerGetActions.setPage(1));
        dispatch(offersForSellerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  const onUpdateStatus = useCallback(
    (offer_id: number, new_status_id: number) => {
      dispatch(
        updateOfferForSellerStatus({
          offer_id,
          new_status_id
        })
      )
        .unwrap()
        .then(() => {
          getOffersArr();
        });
    },
    [dispatch, getOffersArr]
  );

  const columns: ColumnsType<TOfferForSeller> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "approvement_name",
        render: (value: TOfferForSeller) => (
          <Link to={`/myoffers/${value?.offer_id}`}>
            Предложение #{value?.offer_id}{" "}
            {value?.create_dt && `от ${toDisplayDate(value?.create_dt)}`}
          </Link>
        )
      },
      {
        title: "Статус",
        dataIndex: "status_id",
        render: (value: number) => {
          const statusData = offerStatusIdMapper[value];
          return statusData ? (
            <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
          ) : (
            value
          );
        }
      },
      {
        title: "Заявка",
        dataIndex: "application_id",
        render: (value: number) => (
          <Link to={`/availableapplications/${value}`}>Заявка #{value}</Link>
        )
      },
      {
        key: "approve",
        render: (value: TOfferForSeller) => (
          <Button
            onClick={() => onUpdateStatus(value?.offer_id, 101)}
            loading={statusIsLoading}
            icon={<CheckOutlined />}
          >
            Согласовать
          </Button>
        )
      },
      {
        key: "cancel",
        render: (value: TOfferForSeller) => (
          <Button
            onClick={() => onUpdateStatus(value?.offer_id, 107)}
            loading={statusIsLoading}
            danger
            icon={<CloseOutlined />}
          >
            Отклонить
          </Button>
        )
      }
    ],
    [onUpdateStatus, statusIsLoading]
  );

  useEffect(() => {
    dispatch(offersForSellerGetActions.setSearch("statusId:123"));
    getOffersArr();
  }, [dispatch, getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForSellerGetActions.clearState());
      dispatch(offerForSellerUpdateStatusActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <ErrorAlert error={statusError} />

      <Table
        dataSource={offersForSeller}
        columns={columns}
        showHeader={false}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </>
  );
};
