import React, { useCallback, useMemo } from "react";
import { Button, Menu, MenuProps } from "antd";
import { HeaderStyled } from "./Header.styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useAppSelector } from "store/store";
import { userSelectors } from "store/user";

export const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  const { user } = useAppSelector(userSelectors.getState);

  const navigationItems: MenuProps["items"] = useMemo(
    () =>
      [{ path: "/", key: "/", title: "Домой", icon: <HomeOutlined /> }].map(
        ({ path, key, title, icon }) => ({
          key,
          label: <Link to={path}>{title}</Link>,
          icon
        })
      ),
    []
  );

  const onLogin = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const onProfile = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  return (
    <HeaderStyled>
      <div className="demo-logo" />
      <Menu
        theme="dark"
        mode="horizontal"
        items={navigationItems}
        selectedKeys={[path]}
        style={{ flex: 1, minWidth: 0 }}
      />
      {user ? (
        <Button icon={<UserOutlined />} type="primary" onClick={onProfile}>
          {user.full_name}
        </Button>
      ) : (
        <Button onClick={onLogin}>Войти</Button>
      )}
    </HeaderStyled>
  );
};
