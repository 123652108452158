import { ComponentProps } from "react";
import { TApplication } from "types";
import { Tag } from "antd";

export const applicationStatusIdMapper: Record<
  TApplication["application_status_id"],
  {
    shortName: string;
    full_name: string;
    description: string;
    color: ComponentProps<typeof Tag>["color"];
  }
> = {
  92: {
    shortName: "Черновик",
    full_name: "Черновик",
    description: "Заявка не передана на согласование.",
    color: "default"
  },
  93: {
    shortName: "На согласовании",
    full_name: "На согласовании",
    description: "Заявка отправлена на согласование.",
    color: "warning"
  },
  94: {
    shortName: "Согласована",
    full_name: "Согласована",
    description: "Заявка была подтверждена согласователем.",
    color: "processing"
  },
  95: {
    shortName: "Отклонена",
    full_name: "Отклонена",
    description:
      "Заявка была отклонена согласователем. Из этого статуса можно вернуть её в Черновик.",
    color: "error"
  },
  96: {
    shortName: "Сбор предложений",
    full_name: "Сбор предложений",
    description:
      "Заявка отображается в реестре у поставщиков, можно оставлять предложения.",
    color: "processing"
  },
  97: {
    shortName: "Выполнена",
    full_name: "Выполнена",
    description:
      "Заявка выполнена, т.е. счет (счета)  оплачены, товар доставлен.",
    color: "success"
  },
  98: {
    shortName: "Отменена",
    full_name: "Отменена",
    description:
      "Заявка была отменена автором или отв. по заявке, например, потребность стала неактуальной.",
    color: "error"
  },
  121: {
    shortName: "Поставщик определен",
    full_name: "Поставщик определен",
    description: "Заявка окончила сбор предложений",
    color: "processing"
  },
  122: {
    shortName: "Удалена",
    full_name: "Удалена",
    description: "Заявка была удалена",
    color: "error"
  }
};
