import { TOfferPositionFromValue } from "components/common/old";
import { TOfferPosition } from "types";
import { countPrice } from "./countPrice";

export const countOfferPositionsPrice = (
  positions?: Array<TOfferPosition | TOfferPositionFromValue>
) => {
  return (positions || [])?.reduce(
    (acc, { unit_amount, unit_price, discount, vat }) => {
      const price = countPrice(unit_amount, unit_price, discount, 0);
      const priceVAT = countPrice(unit_amount, unit_price, discount, vat);
      return {
        price: acc.price + price,
        priceVAT: acc.priceVAT + priceVAT
      };
    },
    { price: 0, priceVAT: 0 }
  );
};
