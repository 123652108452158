import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TProject } from "types";
import { Wrapper } from "./ProjectsTab.styles";
import { Button, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { ProjectEditForm, ProjectsTable } from "components/common/old";
import {
  entitiesByCabinetIdActions,
  entitiesByCabinetIdSelectors
} from "store/entities/byCabinetId";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";
import {
  projectsByCabinetActions,
  projectsByCabinetSelectors
} from "store/projects/byCabinet";
import { projectAddActions, projectAddSelectors } from "store/projects/add";
import {
  projectUpdateActions,
  projectUpdateSelectors
} from "store/projects/update";
import {
  projectDeleteActions,
  projectDeleteSelectors
} from "store/projects/delete";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { addProject } from "store/projects/add/thunk";
import { updateProject } from "store/projects/update/thunk";
import { deleteProject } from "store/projects/delete/thunk";

export const ProjectsTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    projectAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    projectUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    projectDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [projectInEdit, setProjectsInEdit] = useState<TProject | undefined>(
    undefined
  );
  const [selectedCabinet, setSelectedCabinet] = useState<number | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getProjects = useCallback(
    (cabinetId: number) => {
      dispatch(getProjectsByCabinet(cabinetId));
    },
    [dispatch]
  );

  const getEntities = useCallback(
    (cabinetId: number) => {
      dispatch(getEntitiesByCabinetId(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const project = projects?.find((project) => project?.project_id === id);
      setProjectsInEdit(project);
    },
    [projects]
  );
  const onCancelEdit = useCallback(() => {
    setProjectsInEdit(undefined);
  }, []);

  const onAddProject = useCallback(
    (values: TProject) => {
      dispatch(
        addProject({
          ...values,
          is_active: true
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          selectedCabinet && getProjects(selectedCabinet);
        });
    },
    [dispatch, getProjects, onCancelAdd, selectedCabinet]
  );
  const onEditProject = useCallback(
    (values: TProject) => {
      dispatch(updateProject({ ...projectInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getProjects(selectedCabinet);
        });
    },
    [dispatch, getProjects, onCancelEdit, projectInEdit, selectedCabinet]
  );
  const onDeleteProject = useCallback(
    (id: number) => {
      dispatch(deleteProject(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getProjects(selectedCabinet);
        });
    },
    [dispatch, getProjects, selectedCabinet]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getProjects(selectedCabinet);
  }, [dispatch, getProjects, selectedCabinet]);

  useEffect(() => {
    selectedCabinet && getEntities(selectedCabinet);
  }, [dispatch, getEntities, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(projectAddActions.clearState());
      dispatch(projectUpdateActions.clearState());
      dispatch(projectDeleteActions.clearState());
      dispatch(projectsByCabinetActions.clearState());
      dispatch(entitiesByCabinetIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={setSelectedCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      {selectedCabinet && (
        <>
          {!projectInEdit && (
            <Button
              icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
              onClick={isAdding ? onCancelAdd : onAdd}
            >
              {isAdding ? "Отмена" : "Добавить"}
            </Button>
          )}

          {projectInEdit ? (
            <>
              <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                Отмена
              </Button>
              <ProjectEditForm
                project={projectInEdit}
                entities={entities}
                onSubmit={onEditProject}
                isLoading={updateIsLoading}
                error={updateError}
              />
            </>
          ) : isAdding ? (
            <ProjectEditForm
              entities={entities}
              onSubmit={onAddProject}
              isLoading={addIsLoading}
              error={addError}
            />
          ) : (
            <ProjectsTable
              projects={projects}
              entities={entities}
              onEdit={onEdit}
              onDelete={onDeleteProject}
              isDeleting={deleteIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
