import React, { FC, useMemo } from "react";
import { TApplicationApprovement } from "types";
import { Button, Table } from "antd";
import { applicationApprovementTypeIdMapper } from "utils/mappers";
import { ColumnsType } from "antd/es/table";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

type TApprovementsTableProps = {
  approvements?: TApplicationApprovement[];
  onUpdate: (approvement_id: number, approvement_status_id: number) => void;
  isLoading: boolean;
  forSeller?: boolean;
};

export const ApprovementsTable: FC<TApprovementsTableProps> = ({
  approvements,
  onUpdate,
  isLoading,
  forSeller
}) => {
  const columns: ColumnsType<TApplicationApprovement> = useMemo(
    () => [
      {
        title: "Наименование",
        key: "approvement_name",
        render: (value: TApplicationApprovement) => (
          <Link
            to={`${
              value?.approvement_type_id === 119
                ? forSeller
                  ? "/availableapplications"
                  : "/applications"
                : forSeller
                  ? "/myoffers"
                  : "/offers"
            }/${value?.parent_id}`}
          >
            {[
              applicationApprovementTypeIdMapper[value?.approvement_type_id]
                ?.shortName,
              [`#${value?.parent_id}`, value?.parent_name].join(" - ")
            ].join(" ")}
          </Link>
        )
      },
      {
        key: "approve",
        render: (value: TApplicationApprovement) => (
          <Button
            onClick={() => onUpdate(value?.approvement_id, 109)}
            loading={isLoading}
            icon={<CheckOutlined />}
          >
            Согласовать
          </Button>
        )
      },
      {
        key: "cancel",
        render: (value: TApplicationApprovement) => (
          <Button
            onClick={() => onUpdate(value?.approvement_id, 110)}
            loading={isLoading}
            danger
            icon={<CloseOutlined />}
          >
            Отклонить
          </Button>
        )
      }
    ],
    [forSeller, isLoading, onUpdate]
  );

  return (
    <Table
      dataSource={approvements}
      columns={columns}
      pagination={false}
      showHeader={false}
    />
  );
};
