import React from "react";
import { ContentStyled, LayoutStyled } from "./OldLayout.styles";
import { Outlet } from "react-router-dom";
import { Header, Breadcrumbs, Footer } from "./components";

export const OldLayout = () => {
  return (
    <LayoutStyled>
      <Header />
      <ContentStyled>
        <Breadcrumbs />
        <Outlet />
      </ContentStyled>
      <Footer />
    </LayoutStyled>
  );
};
