import React, { useCallback, useEffect } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Form, FormProps, Grid, Input, Typography } from "antd";
import { TLoginRequest } from "api/unauthorized";
import { Link } from "react-router-dom";
import { CTABlock } from "./Login.styles";
import { loginActions, loginSelectors } from "store/user/login";
import { login } from "store/user/login/thunk";
import { UnauthorizedFormTitle } from "components/common";

export const Login = () => {
  const dispatch = useAppDispatch();
  const screens = Grid.useBreakpoint();

  const { isLoading, error } = useAppSelector(loginSelectors.getState);

  const onLogin: FormProps<TLoginRequest>["onFinish"] = useCallback(
    (values: TLoginRequest) => {
      dispatch(login(values));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(loginActions.clearState());
    };
  }, [dispatch]);

  return (
    <>
      <UnauthorizedFormTitle>Добро пожаловать!</UnauthorizedFormTitle>

      <Form
        layout="vertical"
        name="login"
        // labelCol={{ span: 24 }}
        // wrapperCol={{ span: 24 }}
        style={{ maxWidth: "320px" }}
        onFinish={onLogin}
        // onFinishFailed={onFinishFailed}
        // autoComplete="off"
        labelWrap
        // colon={false}
        requiredMark={false}
      >
        <Form.Item<TLoginRequest>
          label="Логин"
          name="login"
          rules={[
            { required: true, whitespace: true, message: "Укажите логин" }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TLoginRequest>
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Укажите пароль" }]}
          style={{ marginBottom: 8 }}
        >
          <Input.Password />
        </Form.Item>

        <Link
          to="/restorepass"
          style={{
            display: "block",
            width: "fit-content",
            marginLeft: "auto",
            marginBottom: screens?.sm ? 32 : 24
          }}
        >
          <Button type="link" style={{ padding: "4px 5px" }}>
            Забыли пароль?
          </Button>
        </Link>

        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Войти
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />

        <CTABlock>
          <Typography.Text type="secondary">
            Еще не зарегестрированы?
          </Typography.Text>
          <Link to="/registration">
            <Button type="link" style={{ padding: "4px 5px" }}>
              Регистрация
            </Button>
          </Link>
        </CTABlock>
      </Form>
    </>
  );
};
