import React, { FC, useMemo } from "react";
import { TEntity, TProject } from "types";
import { List } from "antd";
import {
  CheckOutlined,
  CloseOutlined,
  DesktopOutlined,
  EnvironmentOutlined,
  HomeOutlined,
  ProjectOutlined,
  PushpinOutlined,
  ShopOutlined,
  SwapRightOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { projectRegionIdMapper } from "utils/mappers";
import { Panel } from "../Panel/Panel";

type TProjectDataProps = {
  project: TProject;
  entity?: TEntity;
};

export const ProjectData: FC<TProjectDataProps> = ({ project, entity }) => {
  const projectData = useMemo(() => {
    return [
      {
        label: "Название",
        value: project?.project_name,
        icon: <ProjectOutlined />
      },
      {
        label: "Описание проекта",
        value: project?.project_description,
        icon: <DesktopOutlined />
      },
      {
        label: "Юр. лицо",
        value: entity ? (
          <Link to={`/companies/${entity?.entity_id}`}>
            {entity?.short_name} <SwapRightOutlined />
          </Link>
        ) : undefined,
        icon: <ShopOutlined />
      },
      {
        label: "Адрес доставки",
        value: project?.delivery_address,
        icon: <HomeOutlined />
      },
      {
        label: "Комментарий для доставки",
        value: project?.delivery_comment,
        icon: <PushpinOutlined />
      },
      {
        label: "Регион",
        value:
          projectRegionIdMapper[project?.region_id]?.fullName ||
          project?.region_id,
        icon: <EnvironmentOutlined />
      },
      {
        label: "Активен",
        value: project?.is_active ? "Активен" : "Не активен",
        icon: project?.is_active ? <CheckOutlined /> : <CloseOutlined />
      }
    ];
  }, [entity, project]);
  return (
    <Panel
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <List
        dataSource={projectData}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={item?.icon}
              title={item?.value}
              description={item?.label}
            />
          </List.Item>
        )}
      />
    </Panel>
  );
};
