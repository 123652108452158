import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorAlert, UserData, UserEditForm } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Alert, Button, Typography } from "antd";
import { userSelectors } from "store/user";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { TPerson } from "types";
import { updateColleague } from "store/colleagues/update/thunk";
import { deleteColleague } from "store/colleagues/delete/thunk";
import { CTABlock, Wrapper } from "./Personal.styles";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { getUser } from "store/user/thunk";
import { entitiesByCabinetIdSelectors } from "store/entities/byCabinetId";
import { getEntitiesByCabinetId } from "store/entities/byCabinetId/thunk";

export const Personal = () => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    colleagueDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);

  const canUpdateDelete = useMemo(
    () => user?.cabinet_role_id === 2 || user?.is_admin,
    [user?.cabinet_role_id, user?.is_admin]
  );

  const getEntities = useCallback(() => {
    user?.cabinet &&
      dispatch(getEntitiesByCabinetId(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...user, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          dispatch(getUser());
        });
    },
    [dispatch, onCancelEdit, user]
  );

  const onDelete = useCallback(() => {
    user &&
      dispatch(deleteColleague(user?.user_id))
        .unwrap()
        .then(() => {
          dispatch(getUser());
        });
  }, [dispatch, user]);

  useEffect(() => {
    !entities && getEntities();
  }, [dispatch, entities, getEntities]);

  useEffect(() => {
    return () => {
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {user?.full_name || "Коллега"}
      </Typography.Title>

      {user ? (
        <>
          {canUpdateDelete && (
            <CTABlock>
              <Button
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={isEditing ? onCancelEdit : onEdit}
              >
                {isEditing ? "Отмена" : "Редактировать"}
              </Button>

              {!isEditing && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={onDelete}
                  loading={deleteIsLoading}
                >
                  Удалить
                </Button>
              )}
            </CTABlock>
          )}

          {!isEditing && <ErrorAlert error={deleteError} />}

          {isEditing ? (
            <UserEditForm
              user={user}
              entities={entities}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <UserData user={user} withActions />
          )}
        </>
      ) : (
        <Alert message="Данные по пользователю отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
