import React from "react";
import { Outlet } from "react-router-dom";
import {
  CredText,
  Footer,
  Header,
  Image,
  ImageWrapper,
  LayoutStyled,
  Logo,
  LogoLink,
  Main,
  MainContent,
  MainWrapper,
  Subtitle,
  TextBlock,
  Title
} from "./UnauthorizedLayout.styles";
import unauthorizedBg from "images/unauthorizedBg.jpg";
import logoNamed from "images/logoNamed.png";

export const UnauthorizedLayout = () => {
  return (
    <LayoutStyled>
      <ImageWrapper>
        <Image src={unauthorizedBg} />
        <TextBlock>
          <Title level={2}>Объединяем тех, кто строит будущее!</Title>
          <Subtitle level={4}>
            Продавцы и закупщики на одной волне для взаимного роста.
          </Subtitle>
        </TextBlock>
      </ImageWrapper>
      <MainWrapper>
        <Header>
          <LogoLink to="/">
            <Logo src={logoNamed} />
          </LogoLink>
        </Header>
        <Main>
          <MainContent>
            <Outlet />
          </MainContent>
          <Footer>
            <CredText>Contractor DEV @{new Date().getFullYear()}</CredText>
          </Footer>
        </Main>
      </MainWrapper>
    </LayoutStyled>
  );
};
