import React, { useCallback, useEffect } from "react";
import { ErrorAlert } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Button, Form, FormProps, Grid, Input, Typography } from "antd";
import {
  TRegistrationRequest,
  TRestorePasswordRequest
} from "api/unauthorized";
import { Link } from "react-router-dom";
import { RegisteredTextBlock } from "./RestorePass.styles";
import {
  restorePasswordActions,
  restorePasswordSelectors
} from "store/user/restorePassword";
import { restorePassword } from "store/user/restorePassword/thunk";
import { UnauthorizedFormTitle } from "components/common";

export const RestorePass = () => {
  const dispatch = useAppDispatch();
  const screens = Grid.useBreakpoint();

  const { isLoading, error, email } = useAppSelector(
    restorePasswordSelectors.getState
  );

  const onRestorePass: FormProps<TRestorePasswordRequest>["onFinish"] =
    useCallback(
      (values: TRestorePasswordRequest) => {
        dispatch(restorePassword(values));
      },
      [dispatch]
    );

  useEffect(() => {
    return () => {
      dispatch(restorePasswordActions.clearState());
    };
  }, [dispatch]);

  if (email) {
    return (
      <>
        <RegisteredTextBlock>
          <UnauthorizedFormTitle>
            Проверьте Ваш почтовый ящик
          </UnauthorizedFormTitle>
          <Typography.Text style={{ textAlign: "center" }}>
            На Вашу почту отправлено письмо с инструкцией по восстановлению
            пароля
          </Typography.Text>
        </RegisteredTextBlock>
        <Link to="https://contractor-b2b.ru">
          <Button size="large" style={{ width: 160 }}>
            Готово
          </Button>
        </Link>
      </>
    );
  }

  return (
    <>
      <UnauthorizedFormTitle>Восстановление пароля</UnauthorizedFormTitle>

      <Form
        layout="vertical"
        name="restorepassword"
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        style={{ maxWidth: "320px" }}
        onFinish={onRestorePass}
        // onFinishFailed={onFinishFailed}
        // autoComplete="off"
        labelWrap
        // colon={false}
        requiredMark={false}
      >
        <Typography.Text
          style={{
            display: "block",
            marginBottom: 16
          }}
        >
          Укажите Ваш e-mail для входа в систему
        </Typography.Text>

        <Form.Item<TRegistrationRequest>
          label="E-mail"
          name="email"
          rules={[{ required: true, message: "Введите e-mail", type: "email" }]}
          style={{
            marginBottom: screens?.sm ? 32 : undefined
          }}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{
            marginBottom: 16
          }}
        >
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            block
            size="large"
          >
            Зарегистрироваться
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />

        <Form.Item
          wrapperCol={{ span: 24 }}
          style={{
            marginBottom: 16
          }}
        >
          <Link to="/login">
            <Button block size="large">
              Назад
            </Button>
          </Link>
        </Form.Item>
      </Form>
    </>
  );
};
