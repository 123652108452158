import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationData,
  ApplicationEditForm,
  EnlargedTag,
  ErrorAlert,
  Files,
  OffersTable
} from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tag,
  Tooltip,
  Typography
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "store/user";
import { ApprovementsBlock, CTABlock, Wrapper } from "./Application.styles";
import {
  applicationUpdateActions,
  applicationUpdateSelectors
} from "store/applications/update";
import { getApplicationById } from "store/applications/byId/thunk";
import { getProjectsByCabinet } from "store/projects/byCabinet/thunk";
import { projectsByCabinetSelectors } from "store/projects/byCabinet";
import {
  ApplicationByIdActions,
  applicationByIdSelectors
} from "store/applications/byId";
import { TApplication } from "types";
import { updateApplication } from "store/applications/update/thunk";
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  EditOutlined
} from "@ant-design/icons";
import { applicationStatusIdMapper } from "utils/mappers";
import { getAccountsByCabinetId } from "store/accounts/byCabinet/thunk";
import { accountsByCabinetIdSelectors } from "store/accounts/byCabinet";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { updateApplicationStatus } from "store/applications/update_status/thunk";
import { applicationUpdateStatusOptions } from "constants/options";
import {
  approvementUpdateActions,
  approvementUpdateSelectors
} from "store/approvements/update";
import {
  applicationUpdateStatusActions,
  applicationUpdateStatusSelectors
} from "store/applications/update_status";
import { updateApprovement } from "store/approvements/update/thunk";
import {
  offersForBuyerByApplicationIdActions,
  offersForBuyerByApplicationIdSelectors
} from "store/offersForBuyer/byApplicationId";
import { getOfferForBuyerByApplicationId } from "store/offersForBuyer/byApplicationId/thunk";
import { getFilesByObject } from "store/files/byObject/thunk";
import {
  filesByObjectActions,
  filesByObjectSelectors
} from "store/files/byObject";

export const Application = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { application } = useAppSelector(applicationByIdSelectors.getState);
  const { offersForBuyer } = useAppSelector(
    offersForBuyerByApplicationIdSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    applicationUpdateSelectors.getState
  );

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    applicationUpdateStatusSelectors.getState
  );
  const { isLoading: approveIsLoading, error: approveError } = useAppSelector(
    approvementUpdateSelectors.getState
  );
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { files } = useAppSelector(filesByObjectSelectors.getState);

  const { application_id } = useParams<{ application_id: string }>();

  const canUpdate = useMemo(
    () =>
      (user?.cabinet?.is_seller === false &&
        (user?.cabinet_role_id === 2 ||
          application?.creator?.user_id === user?.user_id ||
          application?.responsible?.user_id === user?.user_id) &&
        (application?.application_status_id === 92 ||
          application?.application_status_id === 93)) ||
      user?.is_admin,
    [application, user]
  );
  const canEditFiles = useMemo(
    () => !!(user?.cabinet?.is_seller === false || user?.is_admin),
    [user]
  );

  const possibleApprovements = useMemo(() => {
    const openApprovements = application?.approvements?.filter(
      (approvement) => approvement?.approvement_status_id === 108
    );
    if (user?.is_admin) {
      return openApprovements;
    } else if (user?.cabinet?.is_seller === false) {
      if (user?.cabinet_role_id === 2) {
        return openApprovements;
      } else {
        return openApprovements?.filter(
          (approvement) => approvement?.approver?.user_id === user?.user_id
        );
      }
    }
    return [];
  }, [application, user]);

  const project = useMemo(
    () =>
      projects?.find(
        (project) => project?.project_id === application?.project_id
      ),
    [application?.project_id, projects]
  );

  const statusData = useMemo(
    () =>
      application?.application_status_id
        ? applicationStatusIdMapper[application?.application_status_id]
        : undefined,
    [application?.application_status_id]
  );

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      )?.entity,
    [project, user]
  );

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      application
        ? (
            applicationUpdateStatusOptions[
              application?.application_status_id
            ] || []
          ).map((status) => {
            const statusData = applicationStatusIdMapper[status];
            return {
              key: status,
              label: (
                <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
              )
            };
          })
        : [],
    [application]
  );

  const canChangeStatus = useMemo(
    () =>
      !!statusOptions?.length &&
      ((user?.cabinet?.is_seller === false &&
        (user?.cabinet_role_id === 2 ||
          application?.creator?.user_id === user?.user_id ||
          application?.responsible?.user_id === user?.user_id)) ||
        user?.is_admin),
    [application, statusOptions, user]
  );

  const getApplication = useCallback(() => {
    dispatch(getApplicationById(Number(application_id)));
  }, [application_id, dispatch]);

  const getProjects = useCallback(() => {
    user?.cabinet && dispatch(getProjectsByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const getAccounts = useCallback(() => {
    user?.cabinet &&
      dispatch(getAccountsByCabinetId(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const getColleagues = useCallback(() => {
    user?.cabinet &&
      dispatch(getColleaguesByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TApplication) => {
      dispatch(
        updateApplication({
          ...application,
          ...values
        })
      )
        .unwrap()
        .then(() => {
          onCancelEdit();
          getApplication();
        });
    },
    [application, dispatch, getApplication, onCancelEdit]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      application &&
        dispatch(
          updateApplicationStatus({
            application_id: application?.application_id,
            new_status_id: Number(key)
          })
        )
          .unwrap()
          .then(() => {
            getApplication();
          });
    },
    [application, dispatch, getApplication]
  );
  const onApprove = useCallback(
    (approvement_status_id: number) => {
      possibleApprovements?.forEach((approvement) => {
        dispatch(
          updateApprovement({
            approvement_id: approvement?.approvement_id,
            approvement_status_id: approvement_status_id
          })
        )
          .unwrap()
          .then(() => {
            getApplication();
          });
      });
    },
    [dispatch, getApplication, possibleApprovements]
  );

  const getOffers = useCallback(
    (application_id: number) => {
      dispatch(getOfferForBuyerByApplicationId(application_id));
    },
    [dispatch]
  );

  const getFiles = useCallback(
    (application_id: number) => {
      dispatch(
        getFilesByObject({
          id: application_id,
          type: 134
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (user?.cabinet?.is_seller) {
      navigate("/availableapplications", { replace: true, relative: "path" });
    }
  }, [navigate, user]);

  useEffect(() => {
    application_id && getApplication();
  }, [application_id, getApplication]);

  useEffect(() => {
    application && getOffers(application?.application_id);
  }, [application, getOffers]);

  useEffect(() => {
    application && getFiles(application?.application_id);
  }, [application, getFiles]);

  useEffect(() => {
    !projects && getProjects();
  }, [getProjects, projects]);

  useEffect(() => {
    !accounts && getAccounts();
  }, [accounts, getAccounts]);

  useEffect(() => {
    !users && getColleagues();
  }, [users, getColleagues]);

  useEffect(() => {
    return () => {
      dispatch(applicationUpdateStatusActions.clearState());
      dispatch(applicationUpdateActions.clearState());
      dispatch(ApplicationByIdActions.clearState());
      dispatch(approvementUpdateActions.clearState());
      dispatch(offersForBuyerByApplicationIdActions.clearState());
      dispatch(filesByObjectActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px"
        }}
      >
        {application?.application_name || "Заявка"}
        {statusData ? (
          <Tooltip title={statusData?.description}>
            <EnlargedTag color={statusData?.color}>
              {statusData?.shortName}
            </EnlargedTag>
          </Tooltip>
        ) : (
          application?.application_status_id
        )}
      </Typography.Title>

      {application ? (
        <>
          {(canUpdate || canChangeStatus) && (
            <CTABlock>
              {canUpdate && (
                <Button
                  icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                  onClick={isEditing ? onCancelEdit : onEdit}
                >
                  {isEditing ? "Отмена" : "Редактировать"}
                </Button>
              )}

              {canChangeStatus && !isEditing && (
                <Dropdown
                  menu={{ items: statusOptions, onClick: onUpdateStatus }}
                >
                  <Button
                    loading={statusIsLoading}
                    style={{ marginLeft: "auto" }}
                  >
                    <Space>
                      Изменить статус
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
            </CTABlock>
          )}

          <ErrorAlert error={statusError} />

          {!!possibleApprovements?.length && !isEditing && (
            <ApprovementsBlock>
              <Button
                icon={<CheckOutlined />}
                loading={approveIsLoading}
                onClick={() => onApprove(109)}
              >
                Согласовать
              </Button>
              <Button
                icon={<CloseOutlined />}
                onClick={() => onApprove(110)}
                loading={approveIsLoading}
                danger
              >
                Отклонить
              </Button>
            </ApprovementsBlock>
          )}

          <ErrorAlert error={approveError} />

          {isEditing ? (
            <ApplicationEditForm
              application={application}
              user={user}
              users={users}
              projects={projects}
              accounts={accounts}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <>
              {!!offersForBuyer?.length && (
                <OffersTable
                  offers={offersForBuyer}
                  pagination={false}
                  withoutApplication
                />
              )}

              <ApplicationData
                application={application}
                entity={entity}
                project={project}
              />
            </>
          )}

          <Files
            id={application?.application_id}
            type="application"
            files={files}
            onFinish={() => getFiles(application?.application_id)}
            canEdit={canEditFiles}
          />
        </>
      ) : (
        <Alert message="Данные по заявке отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
