import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CompanyEditForm, Panel } from "components/common/old";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { CloseOutlined, PlusOutlined, ShopOutlined } from "@ant-design/icons";
import { Wrapper } from "./Companies.styles";
import { Button, List, Typography } from "antd";
import { Link } from "react-router-dom";
import { TEntity } from "types";
import { addEntity } from "store/entities/add/thunk";
import { getUser } from "store/user/thunk";
import { entityAddActions, entityAddSelectors } from "store/entities/add";

export const Companies = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    entityAddSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);

  const companiesData = useMemo(
    () =>
      (user?.entities || []).map(({ entity }) => ({
        value: entity?.full_name,
        icon: <ShopOutlined />,
        id: entity?.entity_id
      })),
    [user?.entities]
  );

  const canAdd = useMemo(
    () => user?.cabinet_role_id === 2 || user?.is_admin,
    [user?.cabinet_role_id, user?.is_admin]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddEntity = useCallback(
    (values: TEntity) => {
      dispatch(addEntity({ ...values, cabinet_id: user!.cabinet?.cabinet_id }))
        .unwrap()
        .then(() => {
          onCancelAdd();
          dispatch(getUser());
        });
    },
    [dispatch, onCancelAdd, user]
  );

  useEffect(() => {
    return () => {
      dispatch(entityAddActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Компании
      </Typography.Title>

      {canAdd && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      {isAdding ? (
        <CompanyEditForm
          onSubmit={onAddEntity}
          isLoading={addIsLoading}
          error={addError}
        />
      ) : (
        <Panel
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <List
            dataSource={companiesData}
            renderItem={(item) => (
              <Link to={`${item?.id}`}>
                <List.Item>
                  <List.Item.Meta avatar={item?.icon} title={item?.value} />
                </List.Item>
              </Link>
            )}
          />
        </Panel>
      )}
    </Wrapper>
  );
};
