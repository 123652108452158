import { TFileMeta } from "types";
import { RequestPromise, apiRequest } from "utils/api";
import Cookies from "js-cookie";

export const getFilesByObject = (
  data: TFilesByObjectRequest
): RequestPromise<TFilesByObjectResponse> => {
  return apiRequest.get(`/files/object`, {
    params: data,
    headers: {
      Authorization: "Bearer " + Cookies.get("token")
    }
  });
};

export type TFilesByObjectRequest = { id: number; type: number };

export type TFilesByObjectResponse = TFileMeta[];
