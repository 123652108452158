import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";

import { Wrapper } from "./OffersForSeller.styles";
import { Typography } from "antd";
import { OffersTable } from "components/common/old";
import { userSelectors } from "store/user";
import { useNavigate } from "react-router-dom";
import { SearchForm } from "./compoents";
import {
  offersForSellerGetActions,
  offersForSellerGetSelectors
} from "store/offersForSeller/get";
import { getOffersForSeller } from "store/offersForSeller/get/thunk";
import { PAGE_SIZES } from "constants/pagination";

export const OffersForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { offersForSeller, totalElements, page, size, isLoading } =
    useAppSelector(offersForSellerGetSelectors.getState);

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForSeller());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(offersForSellerGetActions.setPage(1));
      dispatch(offersForSellerGetActions.setSearch(search));
      getOffersArr();
    },
    [dispatch, getOffersArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForSellerGetActions.setPage(page));
      } else {
        dispatch(offersForSellerGetActions.setPage(1));
        dispatch(offersForSellerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  useEffect(() => {
    if (!user?.cabinet?.is_seller) {
      navigate("/offers", { replace: true, relative: "path" });
    }
  }, [navigate, user]);

  useEffect(() => {
    getOffersArr();
  }, [getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForSellerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Предложения
      </Typography.Title>

      <SearchForm onSearch={onSearch} isLoading={isLoading} user={user} />

      <OffersTable
        offers={offersForSeller}
        forSeller
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: PAGE_SIZES
        }}
      />
    </Wrapper>
  );
};
